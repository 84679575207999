html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.is-active-left-link{
	background: #ddd;
}

button:disabled {
  opacity: 0.5;
}

.table-monty td {
	text-align: center;
	line-height: 40px;
	font-size: 14px;
}

.table-monty th {
	background: #f5f5f5;
	line-height: 40px;
	/*font-weight: normal;*/
	font-size: 14px;
}
